import { graphql } from "gatsby"
import React from "react"
import Markdown from "react-markdown"
import Layout from "../components/layout"
import dayjs from "dayjs"
import { useI18next } from "gatsby-plugin-react-i18next"

dayjs.locale("tr")

export default function Basin({ data }) {
  const { language } = useI18next()

  const postData = data.markdownRemark.frontmatter
  let title
  let content
  if (language === "en") {
    dayjs.locale("en")
    title = postData.title_en
    content = postData.content_en
  } else {
    dayjs.locale("tr")
    title = postData.title
    content = postData.content
  }
  return (
    <Layout>
      <article className="post">
        <h3 className="post-title">{title}</h3>
        <p className="post-date">
          {dayjs(postData.datetime).format("D MMMM, YYYY")}
        </p>
        <hr />
        <Markdown className="post-content">{content}</Markdown>
        <hr />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BasinQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        title_en
        datetime
        content
        content_en
      }
    }
  }
`
